import React, { useEffect } from 'react';
import banner1 from '../../images/Home/1_banner.jpg';
import banner2 from '../../images/Home/2_banner.jpg';
import banner3 from '../../images/Home/3_banner.jpeg';
import banner4 from '../../images/Home/4_banner.jpg';
import banner5 from '../../images/Home/5_banner.jpg';
import banner6 from '../../images/Home/6_1_2_banner.jpg';
import banner7 from '../../images/Home/7_banner.jpg';
import banner8 from '../../images/Home/8_banner.jpg';
import '../Carousel/carousel.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


function Carousel() {
    useEffect(() => {
        const bootstrap = require('bootstrap');
        const carouselElement = document.querySelector('#carouselExampleIndicators');

        if (carouselElement) {
            const carousel = new bootstrap.Carousel(carouselElement, {
                interval: 4000,
                ride: 'carousel',
                pause: false,
                wrap: true
            });

            return () => carousel.dispose();
        }
    }, []);
    const carouselData = [
        { img: banner1, title: "ICMR-NARFBR  Bio-safety Level-3 Facility", paragraph: "Turkapally, Telangana, INDIA" },
        { img: banner2, title: "Prefilter + H14 HEPA filter housing", paragraph: "ICMR-NARFBR" },
        { img: banner3, title: "The Pirbright Institute - BSL-4 facility", paragraph: "Pirbright, UK" },
        { img: banner4, title: "IAH Pirbright BIBO Housings", paragraph: "with HEPA Filters & Dampers" },
        { img: banner5, title: "H14 + H14 Double filter housing", paragraph: "with Gastight Dampers" },
        { img: banner6, title: "ICAR – ICFMD BSL-3 Facility", paragraph: "Bhubaneswar, Orissa, INDIA" },
        { img: banner7, title: "Krantz HEPA Filter  BIBO Housings with dampers", paragraph: "BSL-3 facility , ICFMD" },
        { img: banner8, title: "Safe Change Filter Housing", paragraph: "SCF hightec with auto scanning " }
    ];
    return (
        <div className='container-fluid p-0'>
            <div id="carouselExampleIndicators"
                className="carousel slide"
                style={{ marginTop: "65px" }}>
                <div className="carousel-indicators">
                    {carouselData.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to={index}
                            className={index === 0 ? "active" : ""}
                            aria-current={index === 0 ? "true" : "false"}
                            aria-label={`Slide ${index + 1}`}
                        ></button>
                    ))}
                </div>
                <div className="carousel-inner">
                    {carouselData.map((item, index) => (
                        <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
                            <img src={item.img} className="d-block w-100" alt={`banner_${index + 1}`} />
                            <div className="carousel-caption d-none d-md-block">
                                <div className="bg-text">
                                    <h5>{item.title}</h5>
                                    <p>{item.paragraph}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    )
}

export default Carousel