import React from 'react';
import '../TechnicalArticle/technical_articles.css';
import Header from '../../../Components/Header/Header';
import Accordion from '../../../Components/Accordition/Acordition';
import Footer from '../../../Components/Footer/Footer';
import { Helmet } from 'react-helmet';

const technicalarticleSections = [
    {
        title: "Products",
        content: (
            <>
                <p>Technical article Opticlean ceiling air outlet</p><hr />
                <p>Technical article Multifunction sail AVACS</p><hr />
                <p>Technical Article Microclimate for large places of assembly - energy efficiency and comfort</p><hr />
                <p>Technical article In situ-Efficiency Measurement for HEPA-Filter</p><hr />
                <p>Technical article Displacement ventilation installed at the most modern police indoor firing range of Europe</p><hr />
                <p>Technical article Design of energy-efficient air distribution systems for industrial buildings</p><hr />
                <p>Technical article Decentralized ventilation units</p><hr />
                <p>Technical article Recirculated air ceiling for operating rooms</p>
            </>
        )
    },
    {
        title: "Clean Air Solutions",
        content: (
            <>
                <p>Technical article Innovation Award to Krantz Clean Air Solutions</p><hr />
                <p>Technical article Energy Recycling Using Exhaust Air Treatment</p>
            </>
        )
    }
]
function TechnicalArticles() {
    return (
        <>
        <Helmet>
        <title>Air Tight Dampers | Safe change filter system</title>
        <meta name="description" content=" " />
        <meta name="robots" content="index, follow" />
        <meta name="geo.placename" content="Dubai" />
        <meta http-equiv="content-language" content="ae" />
        <meta name="geo.region" content="Dubai" />
        <meta property="og:title" content="Air Tight Dampers | Safe change filter system" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="/images/banner_1.1b036fc9c5f12fd2465b.jpg" />
        <meta property="og:locale" content="en-ae" />
        <link rel="alternate" href="https://www.scientechnology.com/technical_articles" hreflang="en-ae" />
        <link rel="canonical" href="https://www.scientechnology.com/technical_articles" />
      </Helmet>
            <section id='technical_article'>
                <Header />
                <div className='container' style={{ width: "75%", margin: "auto", marginTop: "100px" }}>
                    <div className='col-md-9 col-sm-12'>
                        <h2 className='technical_article_title'>Technical-articles</h2>
                        <p className='technical_article_para'>Here you can read technical articles of our business units</p>
                        <Accordion sections={technicalarticleSections} />
                    </div>
                    <hr className='technical_article_hr' />
                </div>
            </section>
            <Footer />
        </>
    )
}

export default TechnicalArticles