import React from 'react';
import '../Disclaimer/disclaimer.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ScrollToTopButton from '../Scrolltotop/Scrolltotop';
import { Helmet } from 'react-helmet';

function Disclaimer() {
    return (
        <>
            <Helmet>
                <title>BSL-3 | BSL-4</title>
                <meta name="description" content=" " />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="Dubai" />
                <meta http-equiv="content-language" content="ae" />
                <meta name="geo.region" content="Dubai" />
                <meta property="og:title" content="BSL-3 | BSL-4" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="/images/banner_1.1b036fc9c5f12fd2465b.jpg" />
                <meta property="og:locale" content="en-ae" />
                <link rel="alternate" href="https://www.scientechnology.com/disclaimer" hreflang="en-ae" />
                <link rel="canonical" href="https://www.scientechnology.com/disclaimer" />
            </Helmet>
            <section id='disclaimer'>
                <Header />
                <div className='container' style={{ width: "75%", margin: "auto", marginTop: "100px" }}>
                    <div className='col-md-9'>
                        <h1 className='desc_title'>Legal Disclaimer</h1>
                        <p className='desc_sub_title'>1. Content</p>
                        <p className='desc_para'>We have taken the greatest care in presenting correct, complete and up-to-date information on our website. The website includes statements concerning the future, reflecting the view of the management of Krantz GmbH at the time in which the statements were given. Ensuing events can diverge greatly from these statements, as the course of events is subject to a wide range of influences. Information provided is supplied without any liability. We maintain absolutely no intention of updating previously made statements concerning the future, but we nevertheless reserve the right to change or update all information presented here at any time without further notice.</p>
                        <br /><br />
                        <p className='desc_sub_title'>2. Referrals and links</p>
                        <p className='desc_para'>We do not assume any liability for the content of external sites linked with our site, including links provided in our website. We have no influence on the content of such sites and they do not represent the opinion of Krantz or Krantz GmbH.</p>
                        <br /><br />
                        <p className='desc_sub_title'>3. Copyright and trademarks</p>
                        <p className='desc_para'>Texts, images, graphics, sound, animations, charts and videos as well as the layout thereof are subject to copyright protection and other protective laws. The reproduction, alteration, transmission or publication of this website in part or in its entirety, except for personal, non-commercial use, is prohibited in all possible forms. The reproduction and use of information and data, which are expressly offered for downloading, is expressly permitted for personal, non-commercial use.</p>
                        <p className='desc_para'>All marks featured on this website (registered trademarks such as logos and commercial descriptions) are property of Krantz GmbH or of third parties and may not be used, permanently downloaded, copied or distributed without previous written permission.</p>
                        <br /><br />
                        <p className='desc_sub_title'>4. Disclaimer</p>
                        <p className='desc_para'>The use of this website does not establish a contractual relationship. This website is to be used at one's own risk. We expressly disclaim all liability for any damage, including consequential damage, that may arise from accessing the Krantz website. This applies in particular to damage involving viruses affecting computers or data processing systems, or damage arising from confidence in the reliability of information found on this website, linked websites or third-party information that can be accessed via this website.v</p>
                    </div>
                    <hr className='contact_hr' />
                </div>
            </section>
            <Footer />
            <ScrollToTopButton />
        </>
    )
}

export default Disclaimer