import React from 'react';
import '../Profild&history/about-profile.css';
import Header from '../../../Components/Header/Header';
import Acordition from '../../../Components/Accordition/Acordition';
import Footer from '../../../Components/Footer/Footer';
import ScrollToTopButton from '../../../Components/Scrolltotop/Scrolltotop';
import { Helmet } from 'react-helmet';

const aboutProfileSections = [
  {
    title: "Beginnings",
    content: (
      <>
        <p>Krantz looks back at a long and eventful history. Already at the beginning we utilized customer proximity and specific customer solutions, and still continue to do so until the present day.<br /></p>
        <p>In 1882, engineer Hermann Krantz founded the enterprise in Aachen. About 40 years later, in the 1920s, Krantz mainly focused on two core areas. First, the specialization on high-temperature water heating. Second, the sale of components for air distribution systems in the textile industry.</p>
      </>
    )
  },
  {
    title: "Krantz goes global",
    content: (
      <p>The next important milestone in the history of Krantz took place in the 50s. Back then, the first components of air distribution systems for the nuclear industry were distributed. The internationalization occurred in the 70s, when Krantz America Inc. in Charlotte (North Carolina) was founded. At the same time Krantz already held 400 patents for components and air distribution systems, establishing itself as market leader.</p>
    )
  },
  {
    title: "Expansion of Clean Air Solutions",
    content: (
      <p>One decade later, in the 1980s, the first thermal oxidizer is being delivered. Further internationalization is also actively pursued in the 90s, for example by signing a large-scale contract for the Airport in Athens (Greece).</p>
    )
  },
  {
    title: "Continued development",
    content: (
      <p>After the turn of the millennium and a social focus on pandemic research, the development and distribution of special filters for laboratories with the highest safety class BSL3 and BSL4 in Hong Kong followed. Krantz also invested into its own further development. In 2009, the in-house Research- and Development Center is being completed. Six years later, in 2015, the Space for Innovation opens to the public. It is a permanent exhibition that conveys the specialties of both Krantz products and services, all shown through the usage of concrete examples.</p>
    )
  },
  {
    title: "Future",
    content: (
      <p>During the more than 130-year corporate history, Krantz changed its name over two dozen times, until the brand finally became a part of the STEAG Corporation in 2018. Since that date, there is a strong interconnectedness between STEAG and Krantz.</p>
    )
  }
];


function AboutProfile() {
  return (
    <>
    <Helmet>
        <title>BIBO | Bag-in Bag-out</title>
        <meta name="description" content=" " />
        <meta name="robots" content="index, follow" />
        <meta name="geo.placename" content="Dubai" />
        <meta http-equiv="content-language" content="ae" />
        <meta name="geo.region" content="Dubai" />
        <meta property="og:title" content="BIBO | Bag-in Bag-out" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="/images/banner_1.1b036fc9c5f12fd2465b.jpg" />
        <meta property="og:locale" content="en-ae" />
        <link rel="alternate" href="https://www.scientechnology.com/about_profile" hreflang="en-ae" />
        <link rel="canonical" href="https://www.scientechnology.com/about_profile" />
      </Helmet>
      <section id='about_profile'>
        <Header />
        <div className='container' style={{ width: "75%", marginTop: "80px" }}>
          <div className='col-md-9'>
            <h2 className='profile_title'>About Us</h2>
            <p className='profile_para mt-5'>We are the partners for <b className='bold'>Krantz GmbH</b>, who develops, designs and manufactures air distribution systems, cooling and heating systems for ceiling and facade installations as well as exhaust gas filtration, dampers and clean air solutions.</p>
            <br />
            <p className='profile_para'>We are always the expert partner at your side , especially concerning plant construction and ventilation services for nuclear power stations.</p>
            <br />
            <p className='profile_para'>Krantz products enjoy an excellent reputation and are used where quality and reliability matters, in both public and in commercial buildings. Examples are clean rooms, nuclear installations, high security laboratories and isolation wards.Only development makes us who we are.</p>
            <br />
            <h4 className='development_title'>Only development makes us who we are.</h4>
            <hr style={{ width: "100%" }} />
            <Acordition sections={aboutProfileSections} />
          </div>
          <hr className='hr_bottom' />
        </div>
      </section>
      <Footer />
      <ScrollToTopButton />
    </>
  )
}

export default AboutProfile