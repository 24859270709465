import React from 'react';
import '../Vedio/vedio.css';
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import { Helmet } from 'react-helmet';

function Vedio() {
    return (
        <>
        <Helmet>
        <title>Biosafety Lab</title>
        <meta name="description" content=" " />
        <meta name="robots" content="index, follow" />
        <meta name="geo.placename" content="Dubai" />
        <meta http-equiv="content-language" content="ae" />
        <meta name="geo.region" content="Dubai" />
        <meta property="og:title" content="Biosafety Lab" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="/images/banner_1.1b036fc9c5f12fd2465b.jpg" />
        <meta property="og:locale" content="en-ae" />
        <link rel="alternate" href="https://www.scientechnology.com/vedio" hreflang="en-ae" />
        <link rel="canonical" href="https://www.scientechnology.com/vedio" />
      </Helmet>
            <section id='vedio'>
                <Header />
                <div className='container' style={{ width: "75%", margin: "auto", marginTop: "100px" }}>
                    <h2>Videos</h2>
                    <div className='row'>
                        <div className='col-md-6'>
                            <iframe
                                width="100%"
                                height="315"
                                src="https://www.youtube-nocookie.com/embed/gLAlHEi3NQE?autohide=1&controls=2&enablejsapi=1&origin=http%3A%2F%2Fwww.krantz.de&showinfo=0"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                            <h5>Multifunctional Sails from Krantz</h5>
                        </div>
                        <div className='col-md-6'>
                            <iframe
                                width="100%"
                                height="315"
                                src="https://www.youtube-nocookie.com/embed/W0kyCE9OYnI?autohide=1&controls=2&enablejsapi=1&origin=http%3A%2F%2Fwww.krantz.de&showinfo=0"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                            <h5>Clean Air Solutions</h5>
                        </div>
                        <div className='col-md-6'>
                            <iframe
                                width="100%"
                                height="315"
                                src="https://www.youtube-nocookie.com/embed/LkLduOpAG48?autohide=1&controls=2&enablejsapi=1&origin=http%3A%2F%2Fwww.krantz.de&showinfo=0"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                            <h5>Get to know Krantz</h5>
                        </div>
                    </div>
                    <hr className='vedio_hr' />
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Vedio